import React, { useEffect, useState } from 'react'

import {
  Typography,
  TextField,
  Button,
  Stack,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material'

import api from '../../../api'

const Feedback = () => {

  const [posting, setPosting] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)

  const handlePost = () => {

    setPosting(true)
    const formEl = document.getElementById('comment')
    const data = formEl.value

    const payload = {
      "content": data
    }

    api.post('/feedback', payload)
      .then(() => {
        formEl.value = ''
        setPosting(false)
        setOpenSuccess(true)
      })
      .catch(() => setPosting(false))

  }

  const handleClear = () => {
    const el = document.getElementById('comment')
    el.value = ''
  }

  const handleSnackbarCLose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSuccess(false)
  }

  useEffect(() => {

  })

  return (
    <>
      <Stack spacing={2} mt={2}>
        <Typography variant='h4'>Feedback</Typography>
        <Typography>
          O UpOLT está em constante desenvolvimento. Sinta-se livre para dar sugestões, elogios e críticas ao sistema.
        </Typography>
        <TextField
          label='Comentário'
          id='comment'
          multiline
          rows={6}
          variant='outlined'
        />
        <Stack direction='row-reverse' spacing={2}>
          <Button variant='contained' onClick={handlePost} disabled={posting}>
            Enviar
            {posting &&

              <CircularProgress size={24} sx={{
                position: 'absolute'
              }} />
            }
          </Button>
          <Button variant='text' onClick={handleClear}>Limpar</Button>
        </Stack>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleSnackbarCLose}
        >
          <Alert variant='filled'>
            Feedback enviado com sucesso!
          </Alert>
        </Snackbar>
      </Stack>
    </>
  )

}

export default Feedback