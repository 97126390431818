import React, { useState, useMemo } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import {
  Container,
  CssBaseline,
  Toolbar
} from '@mui/material'

import {
  createTheme,
  ThemeProvider
} from '@mui/material/styles'

import Header from './layout/header'
import LeftBar from './layout/leftbar'
import NotFound from './pages/notfound'
import OLTs from './pages/olts'
import OltConfig from './pages/olt-config'
import OLT from './pages/olt'
import Alarms from './pages/alarms'
import ONUs from './pages/onus'
import ONU from './pages/onu'
import Dashboard from './pages/dashboard'
import Billing from './pages/billing'
import Preferences from './pages/preferences'
import Feedback from './pages/feedback'
import Map from './pages/map'
// import Footer from './layout/footer'

import ErrorController from './forms/error-controller'



function AppRouter(props) {
  const { setToken } = props
  const [drawerOpen, toggleDrawerOpen] = useState(false)
  const [darkMode, setDarkMode] = useState(props.darkTheme === true ? 'dark' : 'light')
  const [containerFull, setContainerFull] = useState(false)

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode
        }
      }),
    [darkMode]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header setToken={setToken} lang={props.lang} drawerOpen={drawerOpen} toggleDrawerOpen={toggleDrawerOpen} style={{ marginLeft: drawerOpen ? '240px' : '0px' }}></Header>
        <LeftBar drawerOpen={drawerOpen} toggleDrawerOpen={toggleDrawerOpen} />
        <Container maxWidth={containerFull === true ? 'false' : 'lg'} disableGutters={containerFull}>
          <Toolbar />
          <Switch>
            <Route exact path={["/", "/dashboard"]}>
              <Dashboard />
            </Route>
            <Route exact path="/olts">
              <OLTs />
            </Route>
            <Route exact path="/preferences">
              <Preferences darkMode={darkMode} setDarkMode={setDarkMode} />
            </Route>
            <Route exact path='/olts/:id/onus'>
              <ONUs />
            </Route>
            <Route exact path='/olts/:olt_id/onus/:serial'>
              <ONU />
            </Route>
            <Route exact path='/olts/:id'>
              <OLT />
            </Route>
            <Route exact path='/olts/:id/alarms'>
              <Alarms />
            </Route>
            <Route exact path='/olts/:id/config'>
              <OltConfig />
            </Route>
            <Route exact path='/billing'>
              <Billing />
            </Route>
            <Route exact path='/feedback'>
              <Feedback />
            </Route>
            <Route exact path='/mapa'>
              <Map setContainerFull={setContainerFull} />
            </Route>
            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
        </Container>
      </Router>
      {/* <Footer /> */}
      <ErrorController setToken={setToken} />
    </ThemeProvider>
  )
}

export default AppRouter;