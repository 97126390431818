import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import DeleteONUForm from '../../forms/DeleteONUForm'

import {
  Grid,
  Typography,
  Skeleton,
  Paper,
  Button,
  Stack
} from '@mui/material'

import api from '../../../api'


const ONUs = () => {

  const { olt_id, serial } = useParams()
  const [onu, updateONU] = useState()
  const [loading, setLoading] = useState(true)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [redirect, setRedirect] = useState(false)

  useEffect(
    () => {
      api.get(`/olts/${olt_id}/onus/${serial}`)
        .then(response => {
          updateONU(response.data)
          setLoading(false)
        })
    }, [olt_id, serial]
  )

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCLoseDeleteDialog = deleted => {
    setOpenDeleteDialog(false)
    if (deleted === true) {
      setRedirect(true)
    }
  }

  return (
    <>
      {
        redirect && <Redirect push to={`/olts/${olt_id}/onus`} />
      }
      <DeleteONUForm
        open={openDeleteDialog}
        onClose={handleCLoseDeleteDialog}
        serial={serial}
        olt={olt_id}
      />
      <Typography variant='h4' mt={2} mb={2}>ONU {serial}</Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item component={Paper} p={2} xs={12} sm={6} md={4} lg={3} ml={2}>
          <Typography variant='h5' mb={2}>Detalhes da ONU</Typography>
          {loading ? <Skeleton /> : <Typography>Serial: {onu.serial}</Typography>}
          {loading ? <Skeleton /> : <Typography>Status: {onu.operational_state}</Typography>}
          {loading ? <Skeleton /> : <Typography>Código do fabricante: {onu.vendor_id}</Typography>}
          {loading ? <Skeleton /> : <Typography>Equipamento: {onu.equipment_id}</Typography>}
          {loading ? <Skeleton /> : <Typography>Uptime: {onu.uptime}</Typography>}
          {loading ? <Skeleton /> : <Typography>Potência na ONU: {onu.rx_power}</Typography>}
          {loading ? <Skeleton /> : <Typography>Potência enviada pela ONU: {onu.tx_power}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' spacing={2}>
            <Button variant='contained' color='warning' disabled>
              Rogue
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={handleOpenDeleteDialog}
            >
              Remover
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )

}

export default ONUs

