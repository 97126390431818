import React from 'react'


import OnuInfo from './components/onu-info'
import SelectOlt from './components/select-olt'

import './style.css'

const Dashboard = (props) => {
  return (
    <>
      <SelectOlt />
      <OnuInfo />
    </>
  )
}

export default Dashboard