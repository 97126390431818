import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Grid,
  CircularProgress,
  Typography
} from '@mui/material/'

import FindReplace from '@mui/icons-material/FindReplace'
import ImportExport from '@mui/icons-material/ImportExport'
import CloudOff from '@mui/icons-material/CloudOff'
import Warning from '@mui/icons-material/Warning'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'

import api from '../../../../api'

function OnuInfo(props) {

  const { selectedOlt } = props
  const [alarms, setAlarms] = useState(0)
  const [offline, setOffline] = useState(0)
  const [online, setOnline] = useState(0)
  const [discoveries, setDiscoveries] = useState(0);
  const [refreshing, setRefreshing] = useState({
    offline: false,
    online: false,
    discoveries: false,
    alarms: false,
  })


  useEffect(() => {
    if (selectedOlt.id > 0) {
      setRefreshing(refreshing => ({ ...refreshing, discoveries: true }))
      setDiscoveries('-')
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get(`/olts/${selectedOlt.id}/discovered`, {
        cancelToken: source.token
      })
        .then(discoveries => {
          setRefreshing(refreshing => ({ ...refreshing, discoveries: false }))
          if (discoveries && discoveries.length)
            return setDiscoveries(discoveries.length)
          else
            return setDiscoveries(0)
        })
      return () => source.cancel()
    }

  }, [selectedOlt.id])

  useEffect(() => {
    if (selectedOlt.id > 0) {
      setRefreshing(refreshing => ({ ...refreshing, online: true }))
      setOnline('-')
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get(`/olts/${selectedOlt.id}/onus/online`, {
        cancelToken: source.token
      })
        .then(response => {
          if (response.data.length) {
            setOnline(response.data.length)
          } else {
            setOnline(0)
          }
          setRefreshing(refreshing => ({ ...refreshing, online: false }))
        })
      return () => source.cancel()
    }
  }, [selectedOlt.id])

  useEffect(() => {
    if (selectedOlt.id > 0) {
      setRefreshing(refreshing => ({ ...refreshing, offline: true }))
      setOffline('-')
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get(`/olts/${selectedOlt.id}/onus/offline`, {
        cancelToken: source.token
      })
        .then(response => {
          if (response.data.length) {
            setOffline(response.data.length)
          } else {
            setOffline(0)
          }
          setRefreshing(refreshing => ({ ...refreshing, offline: false }))
        })
      return () => source.cancel()
    }
  }, [selectedOlt.id])

  useEffect(() => {
    if (selectedOlt.id > 0) {
      setRefreshing(refreshing => ({ ...refreshing, alarms: true }))
      setAlarms('-')
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get(`/olts/${selectedOlt.id}/alarms`, {
        cancelToken: source.token
      })
        .then(response => {
          if (response.data.length) {
            setAlarms(response.data.length)
          } else {
            setAlarms(0)
          }
          setRefreshing(refreshing => ({ ...refreshing, alarms: false }))
        })
      return () => source.cancel()
    }
  }, [selectedOlt.id])

  const cards = [{
    title: 'discoveries',
    subtitle: 'Onus discovered',
    value: discoveries,
    info: 'info'
  },
  {
    title: 'online',
    subtitle: 'Onus online',
    value: online,
    info: 'info',
    link: `/olts/${selectedOlt.id}/onus`
  },
  {
    title: 'offline',
    subtitle: 'Onus offline',
    value: offline,
    info: 'info',
    link: `/olts/${selectedOlt.id}/onus`
  },
  {
    title: 'alarms',
    subtitle: 'Alarms',
    value: alarms,
    info: 'info',
    link: `/olts/${selectedOlt.id}/alarms`
  },
  ]

  return (
    <Grid container spacing={3}>
      {cards.map((object, key) =>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Card className={`c-onuinfo__card ${object.title}`} key={key}>
            <CardHeader title={object.title}
              subheader={object.subtitle}
              avatar={
                <div>
                  {
                    ((param) => {
                      switch (param) {
                        case 'discoveries':
                          return <FindReplace />
                        case 'online':
                          return <ImportExport />
                        case 'offline':
                          return <CloudOff />
                        case 'alarms':
                          return <Warning />
                        default:
                      }
                    })(object.title)
                  }
                </div>
              }
            />
            <CardActionArea
              component={Link}
              to={object.link ? object.link : '#'}
            >
              <CardContent>
                <Typography variant='h3' align='right'>
                  {refreshing[object.title] ? <CircularProgress /> : object.value}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )
      }
    </Grid >
  );
}

const mapStateToProps = state => ({ selectedOlt: state.selectedOlt })

export default connect(mapStateToProps)(OnuInfo);