import React from 'react'
import { Link } from 'react-router-dom'
import {
  List,
  ListItem,
  Drawer,
  Typography,
  IconButton,
  Toolbar,
  Box
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { menuItens } from '../../../menuItens'


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}


const LeftBar = ({ drawerOpen, toggleDrawerOpen }) => {

  const setDrawerOpen = () => {
    toggleDrawerOpen(!drawerOpen)
  }

  return (
    <Drawer
      variant="temporary"
      open={drawerOpen}
      onClose={setDrawerOpen}
      anchor='left'
    >
      <Toolbar>
        <Box display='flex' flexGrow={1} />
        <IconButton onClick={setDrawerOpen} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Typography variant='h5' m={2}>UpOLT</Typography>
      <List sx={{ minWidth: 250 }}>
        {menuItens.map((text, key) => (
          <ListItem
            key={key}
            button
            component={Link}
            to={`/${text}`}
            onClick={setDrawerOpen}>
            {capitalizeFirstLetter(text)}
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default LeftBar