import React, { useEffect, useState } from 'react'
import api from '../../../api'

import {
  Grid,
  LinearProgress,
  Fab,
  Tooltip,
  Snackbar,
  Typography,
  Container,
  Stack
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'

import OLTCard from './components/OLTCard'
import AddOLTForm from './components/AddOLTForm'

import axios from 'axios'

import './style.css'

const OLTs = () => {

  const [olts, updateOlts] = useState([])
  const [loading, setLoading] = useState(true)
  const [openAddDialog, setOpenDialog] = useState(false)
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false)

  function handleOpen() {
    setOpenDialog(true)
  }

  function handleClose() {
    setOpenDialog(false)
  }

  const handleDeletedAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenDeletedAlert(false)
  };

  function addOLT() {

    //TODO mover responsabilidade para o backend
    const ports_mapping = {
      'DM4610': 8,
      'DM4610HW2': 8,
      'DM4615': 16
    }

    const name = document.getElementById('name').value
    const host = document.getElementById('host').value
    const ssh_user = document.getElementById('ssh_user').value
    const password = document.getElementById('password').value
    const ssh_port = document.getElementById('ssh_port').value
    const vendor = document.getElementById('vendor-selector').innerText
    const model = document.getElementById('model-selector').innerText
    const pon_ports = ports_mapping[model]

    const payload = {
      "name": name,
      "host": host,
      "ssh_user": ssh_user,
      "password": password,
      "ssh_port": ssh_port,
      "vendor": vendor,
      "model": model,
      "pon_ports": pon_ports
    }


    api.post('/olts', payload)
      .then(response => {
        olts.push(response.data)
        setOpenDialog(false)
      })
      .catch(err => {
        console.log(err)
        setOpenDialog(false)
      })
  }

  useEffect(
    () => {
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get('/olts', {
        cancelToken: source.token
      })
        .then(({ data }) => {
          updateOlts(data)
          setLoading(false)
        })
      return () => source.cancel()
    }, []
  )


  return (
    <>
      <Snackbar
        open={openDeletedAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleDeletedAlertClose}
      >
        <Alert
          severity='success'
        >
          OLT removida!
        </Alert>
      </Snackbar>
      <AddOLTForm
        openAddDialog={openAddDialog}
        handleClose={handleClose}
        addOLT={addOLT}
      />
      <Typography variant='h4' mt={2} mb={2}>Suas OLTS!</Typography>

      {loading ?
        <LinearProgress /> :
        <Grid container spacing={3}>
          {
            olts.map(olt => {
              return <OLTCard
                data={olt}
                olts={olts}
                updateOlts={updateOlts}
                openDeletedAlert={setOpenDeletedAlert}
              />
            }
            )
          }
        </Grid>}
      <Container disableGutters>
        <Stack direction='row-reverse'>
          <Tooltip title='Adicionar OLT' placement='top'>
            <Fab
              color="primary"
              aria-label="add"
              onClick={handleOpen}
              sx={{ position: 'fixed', bottom: 16 }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Stack>
      </Container>
    </>
  )
}
export default OLTs