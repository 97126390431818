import React, { useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Button
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import api from '../../api'

const DeleteONUForm = props => {

  const [deleteButtonDisabled, setDeleleteButtonDisabled] = useState(true)

  const checkONUSerial = () => {
    const serial = document.getElementById('serial_field').value
    if (serial === props.serial) {
      setDeleleteButtonDisabled(false)
    } else {
      setDeleleteButtonDisabled(true)
    }
  }

  const deleteONU = () => {
    const serial = props.serial
    const olt = props.olt


    api.delete(`/olts/${olt}/onus/${serial}`)
      .then(() => {
        props.onClose(true)
        console.log(`ONU ${serial} removida!`)
      }
      )

  }

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Typography variant='h6'>Remover ONU</Typography>
        <IconButton
          aria-label='close'
          onClick={() => props.onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Por favor, confirme o serial da ONU que quer remover digitando no campo abaixo.
        </Typography>
        <Typography paragraph>
          {props.serial}
        </Typography>
        <TextField
          autoFocus
          label='Serial da ONU'
          variant='standard'
          id='serial_field'
          onChange={checkONUSerial}
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
        <Button
          color='error'
          disabled={deleteButtonDisabled}
          onClick={deleteONU}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteONUForm