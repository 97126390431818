import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  IconButton,
  Button,
  TextField,
  CircularProgress
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import api from '../../api'

const AddONUForm = props => {

  const [numPorts, setNumPorts] = useState(null)
  const [serial, setSerial] = useState(null)
  const [name, setName] = useState(null)
  const [selectedOlt, setSelectedOlt] = useState('')
  const [selectedPort, setSelectedPort] = useState('')
  const [onuKind, setOnuKind] = useState('bridge')
  const [userVlan, setUserVlan] = useState(null)
  const [oltVLAN, setOltVlan] = useState(null)
  const [allParamsOK, setAllParamsOK] = useState(false)
  const [provisioning, setProvisioning] = useState(false)


  useEffect(() => {
    api.get(`/olts/${props.oltId}`)
      .then(response => {
        setSelectedOlt(response.data.name)
        setNumPorts(response.data.pon_ports)
        console.log(response)
      })
  }, [props.oltId]
  )

  // Check if all params is OK to enable provision button
  useEffect(() => {
    const tests = []

    //testa serial
    const re = new RegExp('^[A-Z, a-z, 0-9]{4}[A-F, 0-9, a-f]{8}$')
    if (re.test(serial)) {
      tests.push(true)
    } else {
      tests.push(false)
    }

    //testa nome
    if (!name) {
      tests.push(false)
    }

    //testa porta
    if (!isNaN(selectedPort) && selectedPort > 0 && selectedPort <= numPorts) {
      tests.push(true)
    } else {
      tests.push(false)
    }

    //testa VLAN da ONU
    if (!isNaN(userVlan) && userVlan > 0 && userVlan <= 4096) {
      tests.push(true)
    } else {
      tests.push(false)
    }

    if (provisioning) {
      tests.push(false)
    } else {
      tests.push(true)
    }

    //testa se tudo está OK
    if (tests.every(Boolean)) {
      setAllParamsOK(true)
    } else {
      setAllParamsOK(false)
    }
  }, [
    serial,
    name,
    numPorts,
    selectedPort,
    userVlan,
    provisioning
  ])

  const addONU = () => {

    const payload = {
      "serial": serial,
      "port": selectedPort,
      "name": name ? name : '',
      "kind": onuKind,
      "user_vlan": userVlan,
      "sw_vlan": oltVLAN ? oltVLAN : userVlan,
      "line_profile": "PPPoE"
    }

    setProvisioning(true)

    api.post(`/olts/${props.oltId}/onus`, payload)
      .then(response => {
        setProvisioning(false)
        handleClean(false)
        props.handleClose(response)
      })
      .catch(err => {
        setProvisioning(false)
        console.log(err)
      })
  }

  const handleClean = cleanAndClose => {
    setSerial(null)
    setName(null)
    setOnuKind('bridge')
    setUserVlan(null)
    setOltVlan(null)
    if (cleanAndClose) {
      props.handleClose()
    }
  }

  return (
    <>
      <Dialog
        open={props.openAddDialog}
        onClose={props.handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Provisionar ONU
          <IconButton
            aria-label='close'
            onClick={props.handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <InputLabel
                htmlFor='select-olt'
                variant='standard'
              >
                OLT
              </InputLabel>
              <Select
                id='select-olt'
                value={selectedOlt}
                variant='standard'
                disabled
                fullWidth
              >
                <MenuItem value={selectedOlt}>{selectedOlt}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                id="serial"
                label="Serial da porta PON"
                required
                fullWidth
                variant='standard'
                onChange={e => setSerial(e.target.value)}
                value={serial}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Nome"
                fullWidth
                variant='standard'
                required
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor='select-port'
                variant='standard'
                required
              >
                Porta
              </InputLabel>
              <Select
                id='select-port'
                value={selectedPort}
                variant='standard'
                fullWidth
                onChange={e => setSelectedPort(e.target.value)}

              >
                {
                  numPorts &&
                  Array.from({ length: numPorts }, (_, i) => <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>)
                }
              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor='select-kind'
                variant='standard'
              >
                Tipo
              </InputLabel>
              <Select
                id='select-kind'
                value={onuKind}
                variant='standard'
                fullWidth
                onChange={e => setOnuKind(e.target.value)}
              >
                <MenuItem value='bridge'>Bridge</MenuItem>
                <MenuItem value='router'>Router</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='user_vlan'
                label='VLAN da ONU'
                type='number'
                fullWidth
                required
                variant='standard'
                value={userVlan ? userVlan : ''}
                onChange={e => setUserVlan(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='sw_vlan'
                label='VLAN na OLT'
                type='number'
                fullWidth
                variant='standard'
                value={oltVLAN ? oltVLAN : ''}
                onChange={e => setOltVlan(e.target.value)}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={() => handleClean(true)}
              disabled={provisioning}
            >
              Cancelar
            </Button>
            <Button
              onClick={addONU}
              color="primary"
              disabled={!allParamsOK}
            >
              Provisionar
              {
                provisioning &&
                <CircularProgress size={24} sx={{
                  position: 'absolute'
                }} />
              }
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AddONUForm