import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import {
  Grid,
  Button,
  Typography,
  Stack,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'

import api from '../../../api'


const OLT = props => {

  const { id } = useParams()
  const [olt, updateOlt] = useState([])
  const [alarms, updateAlarms] = useState([])
  const [discovered, updateDiscovered] = useState(null)
  const [ONUsOnline, updateONUsOnline] = useState(null)
  const [ONUsOffline, updateONUsOffline] = useState(null)
  const [syncingOLT, updateSyncingOLT] = useState(false)


  useEffect(
    () => {
      api.get(`/olts/${id}`)
        .then(response => {
          updateOlt(response.data)
        })
    }, [id]
  )


  useEffect(
    () => {
      api.get(`/olts/${id}/alarms`)
        .then(response => {
          updateAlarms(response.data)
        })
    }, [id]
  )

  useEffect(
    () => {
      api.get(`/olts/${id}/discovered`)
        .then(response => {
          updateDiscovered(response.data)
          console.log(response
          )
        })
    }, [id]
  )

  useEffect(
    () => {
      api.get(`/olts/${id}/onus/offline`)
        .then(response => {
          updateONUsOffline(response.data)
          console.log(response
          )
        })
    }, [id]
  )

  useEffect(
    () => {
      api.get(`/olts/${id}/onus/online`)
        .then(response => {
          updateONUsOnline(response.data)
          console.log(response
          )
        })
    }, [id]
  )

  const handleSyncOLT = () => {
    // updateSyncingOLT(true)
    // api.get(`/olts/${id}/sync`)
    //   .then(response => {
    //     updateSyncingOLT(false)
    //   })
    updateSyncingOLT(true)
    setTimeout(() => {
      updateSyncingOLT(false)
    }, 3000)
  }

  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardContent>
            <Typography variant='h4' mb={2}>Resumo da OLT {id}</Typography>
            <Typography>Nome: {olt.name}</Typography>
            <Typography>Host: {olt.host}</Typography>
            <Typography>Fabricante: {olt.vendor}</Typography>
            <Typography>Modelo: {olt.model}</Typography>
            <Typography>Firmware: {olt.firmware}</Typography>
            <Typography>Portas PON: {olt.pon_ports}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card>
          <CardContent>
            <Typography variant='h4' mb={2}>Status</Typography>
            <Typography>ONUs descobertas: {discovered ? discovered.length : 0} </Typography>
            <Typography>ONUs Online: {ONUsOnline && ONUsOnline.length}</Typography>
            <Typography>ONUs Offline: {ONUsOffline && ONUsOffline.length}</Typography>
            <Typography>Alarmes ativos: {alarms.length}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Stack direction='row' spacing={2}>
          <Button
            component={Link}
            to={`/olts/${id}/onus`}
            variant='contained'
          >
            ONUs
          </Button>
          <Button
            component={Link}
            to={`/olts/${id}/alarms`}
            variant='contained'
          >
            Alarmes
          </Button>
          <Button
            component={Link}
            to={`/olts/${id}/config`}
            variant='contained'
          >
            Configurações
          </Button>
          <Button
            disabled={syncingOLT}
            variant='contained'
            onClick={handleSyncOLT}
          >
            {syncingOLT && <CircularProgress
              size={24}
              sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }}
            />}
            Sincronizar OLT
          </Button>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default OLT