import React, { useState, useEffect } from "react"
import { useParams } from "react-router"

import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  IconButton,
  Stack,
  LinearProgress,
  Tooltip,
} from "@mui/material"

import {
  Delete,
  Search,
  Settings,
  Cancel
} from '@mui/icons-material'

import api from '../../../api'

const OltConfig = props => {

  const { id } = useParams()
  const [vlans, updateVlans] = useState(null)

  useEffect(
    () => {
      api.get(`/olts/${id}/vlan`)
        .then(({ data }) => {
          updateVlans(data)
        }
        )
    }, [id]
  )


  return (
    <>
      <Typography variant='h4' marginY={2}>Configurar OLT {id}</Typography>
      <Typography variant='h5' marginY={2}>Portas Ethernet</Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Porta</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Modo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>SFP+1</TableCell>
              <TableCell>PPPoE</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Trunk</TableCell>
              <TableCell>
                <Stack direction='row'>
                  <Tooltip title='Configurar porta'>
                    <IconButton color="primary"><Settings /></IconButton>
                  </Tooltip>
                  <Tooltip title='Desativar porta'>
                    <IconButton color="error"><Cancel /></IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='h5' marginY={2}>Portas PON</Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Porta</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Modo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>SFP+1</TableCell>
              <TableCell>PPPoE</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Trunk</TableCell>
              <TableCell>
                <Stack direction='row'>
                  <Tooltip title='Configurar porta'>
                    <IconButton color="primary"><Settings /></IconButton>
                  </Tooltip>
                  <Tooltip title='Desativar porta'>
                    <IconButton color="error"><Cancel /></IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='h5' marginY={2}>VLANs</Typography>
      <Stack direction="row" spacing={2} mb={2}>
        <Button variant="contained" color="primary">
          Adicionar VLAN
        </Button>
      </Stack>
      {vlans ?
        <TableContainer component={Paper} sx={{ marginBottom: '2em' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>VLAN</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vlans.map(vlan => (
                <TableRow>
                  <TableCell>{vlan.id}</TableCell>
                  <TableCell>{vlan.name}</TableCell>
                  <TableCell>
                    <Stack direction='row'>
                      <Tooltip title="Ver MACs">
                        <IconButton color="primary"><Search /></IconButton>
                      </Tooltip>
                      <Tooltip title="Remover VLAN">
                        <IconButton color="error"><Delete /></IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer> :
        <LinearProgress />
      }
    </>
  )
}

export default OltConfig