import React from 'react'

import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography
} from '@mui/material'

import Cookies from 'universal-cookie'

import api from '../../../api'

const cookies = new Cookies()

const Preferences = props => {

  const login = cookies.get('login')

  function saveDarkMode(darkMode, login) {
    const payload = {
      "frontend_dark_mode": darkMode
    }
    api.put(`/users/${login}/dark-mode`, payload)
      .then(res => {
        console.log(res)
      })
  }

  return (
    <>
      <Typography variant='h4' mt={2} mb={2}>Preferências</Typography>
      <FormGroup>
        <FormControlLabel
          label='Modo Escuro'
          control={<Switch />}
          checked={props.darkMode === 'dark' ? true : false}
          onChange={() => {
            if (props.darkMode === 'dark') {
              props.setDarkMode('light')
              saveDarkMode(false, login)
            } else {
              props.setDarkMode('dark')
              saveDarkMode(true, login)
            }
          }}
        />
      </FormGroup>
    </>
  )

}

export default Preferences