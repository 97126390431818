import { useEffect, useState } from 'react'

import {
  InputLabel,
  MenuItem,
  Select,
  Box
} from '@mui/material'

import api from '../../../../api'

import { connect } from 'react-redux'
import { setOlt } from '../../../../store/actions'


function SelectOlt(props) {

  const [olts, setOlts] = useState([])
  const [oltIndex, setOltIndex] = useState(0)
  const { setOlt } = props

  const onChange = (ev) => {
    const index = ev.target.value
    setOltIndex(index)
    setOlt(olts[index])
  }

  useEffect(() => {
    api.get('/olts')
      .then(response => {
        setOlts(response.data)
        setOlt(response.data[0])
      })
  }, [setOlt])

  return (
    <Box marginY={2}>
      <InputLabel
        htmlFor='select-olt'
        variant='standard'
      >
        Seleciona a OLT
      </InputLabel>
      <Select
        value={oltIndex}
        style={{ width: 250 }}
        onChange={onChange}
        variant='standard'
        id='select-olt'
        fullWidth
      >
        {olts.map && olts.map((object, index) => <MenuItem key={`olt-info-${index}`} value={index} >{object.name}</MenuItem>)}
      </Select>
    </Box>
  )
}

const mapStateToProps = state => ({ selectedOlt: state.selectedOlt })
const mapDispatchToProps = { setOlt }

export default connect(mapStateToProps, mapDispatchToProps)(SelectOlt)