import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import WarningIcon from '@mui/icons-material/Warning'
import { Button } from '@mui/material'
import Cookies from 'universal-cookie'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import api from '../../api'

const cookies = new Cookies()

function ModalAlert(props) {
  const [open, setOpen] = useState(false)
  const [internetError, setInternetError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const { setToken } = props

  function handleLogout() {
    cookies.remove('access_token', { path: '/' })
    setToken('')
  }

  api.interceptors.response.use((response) => response, (error) => {
    const status = (error.response && error.response.status) || 0

    if (navigator.onLine) {
      setInternetError(false)
      switch (status) {
        case 401:
          setErrorMessage('Please, login again.')
          setOpen(true)
          break
        case 500:
          setErrorMessage('Internal error. Please, try again later.')
          setOpen(true)
          break
        default:
          break
      }
    } else {
      setInternetError(true)
    }
    throw error
  })

  return (
    <>
      <Dialog
        open={open}
      >
        <DialogTitle>
          <WarningIcon color={'error'} />
          {errorMessage}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleLogout}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={internetError}
        autoHideDuration={6000}
        onClose={() => setInternetError(false)}
      >
        <Alert severity="error">Internet disconnected!</Alert>
      </Snackbar>
    </>
  )
}

export default ModalAlert