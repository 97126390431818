import { useState } from "react";
import Cookies from 'universal-cookie';

import '../App.css';

import AppRouter from "./AppRouter";
import Login from "./pages/login";
import api from '../api'

const cookies = new Cookies();

function App() {
  const [token, setToken] = useState(cookies.get('access_token') || '');
  const [darkMode, setDarkMode] = useState(false);

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }

  return (
    <div className="root">
      {token === "" ? <Login setToken={setToken} setDarkMode={setDarkMode}></Login> : <AppRouter setToken={setToken} token={token} darkTheme={darkMode}></AppRouter>}
    </div>
  )
}

export default App;
