import { createStore } from 'redux'
import { SET_OLT } from './actions'


const initialState = {
  selectedOlt: { id: 0 },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_OLT:
      return {
        ...state,
        selectedOlt: action.olt
      }
    default:
      return {
        ...state,
      }
  }
}

const store = createStore(reducer)

export default store