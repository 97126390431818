import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
  Grid,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Stack,
  Container,
  // Badge,
  // PublicIcon
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuIcon from '@mui/icons-material/Menu'

import NavLink from './NavLink'
import { menuItens } from '../../../menuItens'

const cookies = new Cookies()

function Header(props) {
  const { setToken, drawerOpen, toggleDrawerOpen } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [userName, setUserName] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    cookies.remove('access_token', { path: '/' })
    setToken('')
  }


  const setDrawerOpen = () => {
    toggleDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    async function fetchUserName() {
      const first_name = await cookies.get('first_name')
      setUserName(first_name)
    }
    fetchUserName()
  }, [userName]
  )

  const screenWidthMatch = useMediaQuery('(min-width:629px)')

  return (
    <AppBar
      position="fixed"
    >
      <Container disableGutters>
        <Grid>
          <Toolbar>
            {!screenWidthMatch &&
              <IconButton color='inherit' onClick={() => setDrawerOpen()} size="large">
                <MenuIcon />
              </IconButton>
            }
            <Grid container justify="space-between">
              {screenWidthMatch &&
                <Grid item xs={2}>
                  <Typography variant="h6">
                    UpOLT
                  </Typography>
                </Grid>
              }
              <Grid
                item
                xs
              > <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                  {screenWidthMatch ?
                    <nav style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {menuItens.map(text => (
                        <NavLink to={`/${text}`} key={menuItens.indexOf(text)}>
                          {text}
                        </NavLink>
                      ))}
                    </nav>
                    :
                    <h3 style={{
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 'unset'
                    }}>
                      UpOLT
                    </h3>
                  }
                </Grid>
              </Grid>
              {/* Será implementado quando as ONUs descobertas estiverem no redux */}
              {/* <Grid item xs={1}>
            <Badge color="error" badgeContent={123} max={99}>
              <PublicIcon />
            </Badge>
          </Grid> */}
              <Grid item xs={2}>
                <Stack direction='row-reverse'>
                  <Button color="inherit" onClick={handleClick}>
                    Olá {userName}
                  </Button>
                </Stack>
              </Grid>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to='/billing'
                >
                  Licença
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to='/preferences'
                >
                  Preferências
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to='/feedback'
                >
                  Feedback
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </Grid>
      </Container>
    </AppBar>
  );
}

export default Header