import React, { useState } from 'react'

import {
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  IconButton
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const AddOLTForm = ({ openAddDialog, handleClose, addOLT }) => {

  const [vendor, setVendor] = useState('Datacom')
  const [model, setModel] = useState('DM4610')

  const handleSelectVendor = event => {
    setVendor(event.target.value)
  }

  const handleSelectModel = event => {
    setModel(event.target.value)
  }

  return (
    <Dialog
      open={openAddDialog}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>
        Adicionar OLT
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Entre com os dados da OLT
        </DialogContentText>
        <Grid container spacing={2} mt={1}>
          <Grid item sm={12}>
            <TextField
              autoFocus
              id="name"
              label="Nome"
              type="text"
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="host"
              label="IP"
              type="text"
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item sm={5}>
            <TextField
              id="ssh_user"
              label="Usuário SSH"
              type="text"
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item sm={5}>
            <TextField
              id="password"
              label="Senha SSH"
              type="password"
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              id="ssh_port"
              label="Porta SSH"
              type="number"
              defaultValue='22'
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel
              htmlFor='vendor-selector'
            >
              Fabricante
            </InputLabel>
            <Select
              onChange={handleSelectVendor}
              value={vendor}
              variant='standard'
              fullWidth
              id='vendor-selector'
            >
              <MenuItem
                value='Datacom'
              >
                Datacom
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <InputLabel
              htmlFor='model-selector'
            >
              Modelo
            </InputLabel>
            <Select
              variant='standard'
              fullWidth
              id='model-selector'
              value={model}
              onChange={handleSelectModel}
            >
              <MenuItem
                value='DM4610'
              >
                DM4610
              </MenuItem>
              <MenuItem
                value='DM4610HW2'
              >
                DM4610HW2
              </MenuItem>
              <MenuItem
                value='DM4615'
              >
                DM4615
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={addOLT} color="primary">
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddOLTForm