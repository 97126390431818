import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  LinearProgress
} from '@mui/material'

import api from '../../../api'

import './style.css'

const Alarms = props => {

  const { id } = useParams()
  const [alarms, updateAlarms] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(
    () => {
      api.get(`/olts/${id}/alarms`)
        .then(({ data }) => {
          const alarms = data
          alarms.map(alarm => alarm.id = alarms.indexOf(alarm))
          updateAlarms(alarms)
          setLoading(false)
        })
    }, [id]
  )


  return (
    <>
      <Typography title variant='h4' m={2}>
        Alarmes
      </Typography>
      {
        loading ?
          <LinearProgress /> :
          <TableContainer
            component={Paper}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: `calc(100vh - 150px - 0.3em )`}}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Fonte</TableCell>
                  <TableCell>Severidade</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alarms.map(row => (
                  <TableRow
                    key={alarms.indexOf(row)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.source}</TableCell>
                    <TableCell>{row.severity}</TableCell>
                    <TableCell>{row.triggered_on_date}</TableCell>
                    <TableCell>{row.triggered_on_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      }
    </>
  )

}

export default Alarms