import React from 'react'
import { Link } from 'react-router-dom'


function NavLink({ to, children }) {

    return (
        <Link to={to} style={{
            textDecoration: 'none',
            color: '#fff',
            margin: '10px'
        }}>
            {children.toUpperCase()}
        </Link>
    )
}

export default NavLink