import React from 'react'

import './style.css'

const NotFound = () => {
    return (
        <h1>Ops... Essa página não foi encontrada.</h1>
    )
}

export default NotFound