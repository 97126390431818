import React from 'react'
import { useEffect } from 'react'
import GoogleMapReact from 'google-map-react'

const Marker = ({ imgSrc, title }) => {
  return <div className='marker'>
    {title}
    <img style={{ width: 100 }} src={imgSrc} alt="" />
  </div>
}

const Map = props => {
  const defaultProps = {
    center: {
      lat: -12.7939919,
      lng: -38.397563
    },
    zoom: 11
  }

  useEffect(
    () => {
      props.setContainerFull(true)
      return () => {
        props.setContainerFull(false)
      }
    }
  )

  return (
    <div style={{ with: '100%', height: 'calc(100vh - 64px)' }}>
      <GoogleMapReact
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{ streetViewControl: true }}
        bootstrapURLKeys={{ key: 'AIzaSyDJPfOMMLVH5Qfj2IfcNMt4lYuiyORY_k4' }}
      >
      </GoogleMapReact>
    </div>
  )
}

export default Map