import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'
import { Button, CssBaseline, TextField } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import makeStyles from '@mui/styles/makeStyles';
import Refresh from '@mui/icons-material/Refresh'



import api from '../../../api'


const cookies = new Cookies()

const Login = props => {
  const { setToken, setDarkMode } = props

  const [passwordError, setPasswordError] = useState(false)
  const [showLoginProcess, setShowLoginProcess] = useState(false)

  function doLogin() {

    const login = document.getElementById('login').value
    const password = document.getElementById('password').value
    setShowLoginProcess(true)
    api.post('/login', {
      'login': login,
      'password': password
    }).then(response => {
      setDarkMode(response.data.frontend_dark_theme)
      setToken(response.data.access_token)
      cookies.set('access_token', response.data.access_token, { path: '/', maxAge: 900 })
      cookies.set('login', response.data.login, { path: '/', maxAge: 900 })
      cookies.set('first_name', response.data.first_name, { path: '/', maxAge: 900 })
      cookies.set('last_name', response.data.last_name, { path: '/', maxAge: 900 })
      setShowLoginProcess(false)
    }).catch(err => {
      setPasswordError(err)
      setShowLoginProcess(false)
    })

  }
  useEffect(() => {
    window.document.getElementById('c-login__root').addEventListener('keydown', ev => {
      if (ev.key === "Enter")
        doLogin()
    })
  }
  )


  const useStyles = makeStyles((theme) => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }))

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: 8 }}>
      <CssBaseline />
      <div id="c-login__root" className={classes.paper}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          error={Boolean(passwordError)}
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          onClick={() => { setPasswordError(false) }}
          autoCapitalize="none"
          autoCorrect="off"
        />
        <div style={{ position: 'relative', width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={Boolean(passwordError)}
            name="password"
            label="Senha"
            type='password'
            id="password"
            autoComplete="current-password"
            onClick={() => { setPasswordError(false) }}
          />
        </div>
        {passwordError && <Typography color="error">Nome de usuário ou senha incorretos!</Typography>}
        <Button
          onClick={() => doLogin()}
          fullWidth
          disabled={showLoginProcess ? true : false}
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
        >
          {showLoginProcess && <Refresh className="infinity-spin" />}
          Login
        </Button>
      </div>
    </Container>
  )
}

export default Login