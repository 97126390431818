import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Fab,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  LinearProgress,
  Snackbar,
  Alert,
  Stack,
  Container,
  Tooltip
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import OnuForm from '../../forms/AddONUForm'
import { connect } from 'react-redux'

import axios from 'axios'
import api from '../../../api'

import './style.css'

const ONUs = props => {

  const { id } = useParams()
  const [onus, updateONUs] = useState([])
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openAddDialog, setOpenDialog] = useState(false)
  const [openAddedAlert, setOpenAddedAlert] = useState(false)

  function handleOpen() {
    setOpenDialog(true)
  }

  function handleClose(response) {
    if (response) {
      if (response.status === 201) {
        setOpenAddedAlert(true)
        updateONUs([...onus, response.data])
      }
    }
    setOpenDialog(false)
  }

  const handleAddedAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAddedAlert(false)
  }

  useEffect(
    () => {
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      api.get(`/olts/${id}/onus`, {
        cancelToken: source.token
      })
        .then(({ data }) => {
          const onus = data
          if (onus.map) {
            onus.map(onu => onu.id = onus.indexOf(onu))
            updateONUs(onus)
            setLoading(false)
          } else {
            if (onus.msg || onus.mensage)
              setErr(onus)
            setLoading(false)
          }
        })
      return () => source.cancel()
    }, [id]
  )


  return (
    <>
      <OnuForm
        openAddDialog={openAddDialog}
        handleClose={handleClose}
        oltId={id}
      />
      <Snackbar
        open={openAddedAlert}
        autoHideDuration={6000}
        onClose={handleAddedAlertClose}
      >
        <Alert
          variant='filled'
          severity='success'
        >
          ONU Provisionada!
        </Alert>
      </Snackbar>
      <h2>{err && err.msg}</h2>
      {loading ?
        <LinearProgress /> :

        <TableContainer
          component={Paper}
          sx={
            {
              '&:last-child td, &:last-child th': { border: 0 },
              height: `calc(100vh - 150px - 0.3em )`
            }
          }
        >
          <Typography variant='h4' m={2}>ONUs</Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Porta</TableCell>
                <TableCell>Serial</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {onus.map(row => (
                <TableRow
                  key={row.serial}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    textDecoration: 'none'
                  }}
                  component={Link}
                  to={`/olts/${id}/onus/${row.serial}`}
                  hover
                >
                  <TableCell component="th" scope="row">
                    {row.port}
                  </TableCell>
                  <TableCell>{row.serial}</TableCell>
                  <TableCell>{row.operational_state}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <Container disableGutters>
        <Stack direction='row-reverse'>
          <Tooltip title='Provisionar ONU' placement='top'>
            <Fab color='primary'
              aria-label='add'
              onClick={handleOpen}
              sx={{ position: 'fixed', bottom: 16 }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Stack>
      </Container>
    </>
  )
}

const mapStateToProps = state => ({
  selectedOlt: state.selectedOlt,
})

export default connect(mapStateToProps)(ONUs)
