import React from 'react'

import {
    Typography
} from '@mui/material'

const Billing = props => {
    return (
        <>
            <Typography variant='h4' mt={2} mb={2}>Billing</Typography>
            <Typography paragraph>
                Aqui ficará o histórico de pagamento assim como faturas em aberto e plano selecionado.
            </Typography>
        </>
    )
}

export default Billing