import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CardActionArea
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import api from '../../../../api'
import '../style.css'

const OLTCard = ({ data, olts, updateOlts, openDeletedAlert }) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true)
  const [raised, setRaised] = useState(false)

  const openMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const checkOLTNameForDelete = () => {
    const olt_name = document.getElementById('olt_name').value
    if (olt_name === data.name) {
      setDeleteButtonDisabled(false)
    } else {
      setDeleteButtonDisabled(true)
    }
  }

  const handleDelete = () => {

    const olt_id = data.id

    api.delete(`/olts/${olt_id}`)
      .then(() => {
        updateOlts(() => olts.filter(item => item !== data))
        setOpenDeleteDialog(false)
        openDeletedAlert(true)
      })
      .catch(err => console.log(err))

  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true)
            closeMenu()
          }}
        >
          Excluir
        </MenuItem>
        <MenuItem
          onClick={closeMenu}
          component={Link}
          to={`/olts/${data.id}/config`}
        >
          Configurações
        </MenuItem>
      </Menu>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>Exluir OLT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Cuidado. Essa operação é irreversível.
            </Typography>
            <Typography paragraph>
              Para remover digite o nome da OLT para confirmar a exclusão.
            </Typography>
            <Typography paragraph >
              {data.name}
            </Typography>
          </DialogContentText>
          <TextField
            fullWidth
            label='Nome da OLT'
            id='olt_name'
            margin='dense'
            onChange={checkOLTNameForDelete}
            autoFocus
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={handleCloseDeleteDialog}
          >
            Cancelar
          </Button>
          <Button
            color='error'
            variant='contained'
            disabled={deleteButtonDisabled}
            onClick={handleDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        raised={raised}
        onMouseOver={() => setRaised(true)}
        onMouseOut={() => setRaised(false)}
      >
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={openMenu} size="large">
              <MoreVertIcon />
            </IconButton>
          }
          title={data.name}
          subheader={data.host}
          titleTypographyProps={{ noWrap: true, className: 'cardTitle' }}
        />
        <CardActionArea
          component={Link}
          to={`/olts/${data.id}`}
        >
          <CardMedia
            component='img'
            image='/img/olts/datacom/DM4610-8GPON.png'
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Fabricante: {data.vendor}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Modelo: {data.model}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Portas PON: {data.pon_ports}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size='small'
            color='primary'
            component={Link}
            to={`/olts/${data.id}`}
          >
            Detalhes
          </Button>
          <Button
            size='small'
            color='primary'
            component={Link}
            to={`/olts/${data.id}/onus`}
          >
            Listar ONUs
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );

}

export default OLTCard